import React from 'react'
import Button from '../../atoms/Button/Button'
import { ArrowRight } from '../../atoms/NewIcons'
import Link from 'next/link'
import MyImage from '../../atoms/MyImage/MyImage'

const CatalogoBrand = ({ img, title, description, cta, url, backgroundColor, img_size }) => {
  const default_img_width = 179
  const img_height = img_size
    ? Math.round((img_size.height * default_img_width) / img_size.width)
    : 1000

  return (
    <div
      className="catalogo-brand"
      style={{ backgroundColor: backgroundColor ? backgroundColor : '#5cabe4' }}
    >
      <div className="catalogo-brand__content">
        {title && <p className="catalogo-brand__title">{title}</p>}
        {description && <p className="catalogo-brand__description">{description}</p>}
        {url && (
          <Link href={url ? url : ''}>
            <Button
              size="large"
              responsive={true}
              label={cta}
              variant="secondary"
              colorVariant="black"
              icon={<ArrowRight />}
              className="catalogo-brand__cta"
            />
          </Link>
        )}
      </div>
      {img && (
        <MyImage
          className="catalogo-brand__img"
          src={img}
          alt={title}
          title={title}
          width={default_img_width}
          height={img_height}
          priority={false}
          loading="lazy"
        />
      )}
    </div>
  )
}

export default CatalogoBrand
