import React from 'react'
import Link from 'next/link'
import MyImage from '../../atoms/MyImage/MyImage'
import Image from 'next/image'

const CategoriaCardHome = ({ main_image, titolo, slug, backgroundColor, index }) => {
  return (
    <Link href={slug ? slug : ''}>
      <a className={`categoria-card-home categoria-card-home--color-${(index % 8) + 1}`}>
        <div className="categoria-card-home__content">
          {titolo && <p className="categoria-card-home__title">{titolo}</p>}
          {main_image && (
            <div className="categoria-card-home__image-box">
              <Image src={main_image} alt={titolo} title={titolo} width={150} height={150} />
            </div>
          )}
        </div>
      </a>
    </Link>
  )
}

export default CategoriaCardHome
