import React, { useRef } from 'react'
import Carousel from '../../molecules/Carousel/Carousel'
import CategoriaCardHome from '../../molecules/CategoriaCardHome/CategoriaCardHome'
import CatalogoBrand from '../../molecules/CatalogoBrand/CatalogoBrand'
import CatalogoBanner from '../../molecules/CatalogoBanner/CatalogoBanner'
import HomepageServiceCard from '../../molecules/HomepageServiceCard/HomepageServiceCard'
import NewsCard from '../../molecules/NewsCard/NewsCard'
import LasciatiIspirare from '../../organisms/LasciatiIspirareCard/LasciatiIspirareCard'
import NewProdottoCard from '../../molecules/NewProdottoCard/NewProdottoCard'
import { Trans } from '../../../components/index'
import { Button } from '../../atoms'
import { ChevronDoubleDown, ArrowRight } from '../../atoms/NewIcons'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useTrans from '../../../hooks/useTrans'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const HomepageTemplate = ({ homepageData, newsData, homepageMenuData, prezzoLoaded }) => {
  const scrollDownRef = useRef(null)
  const { utente } = useSelector((state) => state.utente)
  const t = useTrans()

  return (
    <div className="homepage_template">
      <React.Fragment>
        {/* Carousel */}
        {homepageData.slides.length !== 0 && (
          <div className="homepage_template__section">
            <div className="homepage_template__carousel">
              <Carousel slides={homepageData.slides} />
            </div>
            <button
              className="homepage_template__scrolldown"
              onClick={() => scrollDownRef.current.scrollIntoView()}
            >
              <ChevronDoubleDown />
            </button>
            <div ref={scrollDownRef}></div>{' '}
            {/* Non so cosa c'è dopo, scrollo fino alla fine del blocco */}
          </div>
        )}

        {/* Categorie in evidenza */}
        {homepageData.categorie_evidenza.length !== 0 && (
          <div className="homepage_template__section homepage_template__section--categorie">
            <div className="w-container">
              <h1 className="homepage_template__section-title">
                <Trans chiave="Categorie in evidenza" />
              </h1>
              <div className="homepage_template__categorie">
                {homepageData.categorie_evidenza.map((categoria, k) => {
                  return (
                    <div
                      className="homepage_template__categorie__item"
                      key={'categorie-evidenza-' + k.toString()}
                    >
                      <CategoriaCardHome
                        main_image={categoria.immagine_categoria}
                        titolo={categoria.nome_categoria}
                        slug={categoria.url}
                        index={k}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        {/* Best Seller */}
        {homepageData.best_seller.map((best_seller, index) => (
          <div
            className="homepage_template__section homepage_template__section--best-seller"
            key={'best-seller-' + index}
          >
            <div className="w-container">
              <h1 className="homepage_template__section-title">{best_seller.nome_categoria}</h1>
              <div className="swiper--with-pagination swiper--lateral-padding">
                <Swiper
                  navigation={true}
                  pagination={true}
                  spaceBetween={48}
                  slidesPerView={4}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    576: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 4,
                      threshold: 10,
                    },
                  }}
                >
                  {best_seller.prodotti_categoria.map((prodotto, k) => {
                    return (
                      <SwiperSlide key={k}>
                        <NewProdottoCard
                          utente={utente}
                          prodotto={prodotto}
                          priceLoaded={prezzoLoaded}
                          index={k}
                        />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>

              <Link href={best_seller.url ? best_seller.url : ''}>
                <a>
                  <Button
                    label={t('Vedi tutti')}
                    variant="ghost"
                    icon={<ArrowRight />}
                    size="large"
                    className="homepage_template__bestSeller__cta"
                  />
                </a>
              </Link>
            </div>
          </div>
        ))}

        {/* Lasciati ispirare */}
        {homepageData.lasciati_ispirare.length !== 0 && (
          <div className="homepage_template__section homepage_template__section--inspire">
            <LasciatiIspirare
              utente={utente}
              categorie={homepageData.lasciati_ispirare}
              prezzoLoaded={prezzoLoaded}
            />
          </div>
        )}

        {/* Banners */}
        {homepageData.banners.length !== 0 && (
          <div className="homepage_template__section homepage_template__section--brand">
            <div className="w-container">
              <div className="homepage_template__brands">
                {homepageData.banners.map((banner, k) => (
                  <div className="homepage_template__brands__item" key={'brands-' + k.toString()}>
                    <CatalogoBrand
                      title={banner.titolo}
                      description={banner.descrizione}
                      img={banner.image}
                      cta={banner.testo_call_to_action}
                      url={banner.url}
                      img_size={banner?.image_size}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* I nostri servizi */}
        {'children' in homepageMenuData &&
          homepageMenuData.children.map((hp, hpIndex) => (
            <>
              {hp.key === 'hp-servizi' && (
                <div
                  className="homepage_template__section homepage_template__section--servizi"
                  key={hp.key + '-' + hpIndex.toString()}
                >
                  <div className="w-container">
                    <h1 className="homepage_template__section-title">{hp.cta}</h1>
                    <HomepageServiceCard servizi={hp.children} url={hp.final_url} />
                  </div>
                </div>
              )}
            </>
          ))}

        {/* Banner Singolo */}
        {homepageData.banner_in_fondo.length !== 0 && (
          <div className="homepage_template__section homepage_template__section--banner">
            {homepageData.banner_in_fondo.map((obj, k) => {
              return (
                <div className="w-container" key={'catalogo-banner-' + k.toString()}>
                  <CatalogoBanner
                    description={obj.descrizione}
                    img={obj.image}
                    title={obj.titolo}
                    cta={obj.testo_call_to_action}
                    url={obj.url}
                    index={k}
                  />
                </div>
              )
            })}
          </div>
        )}
      </React.Fragment>
      {newsData?.length > 0 && (
        <div className="homepage_template__section homepage_template__section--news">
          <div className="w-container">
            <h1 className="homepage_template__section-title">
              <Trans chiave="News" />
            </h1>
            <div className="homepage_template__news">
              <div className="homepage_template__news__list">
                {newsData.slice(0, 4).map((news, i) => {
                  return (
                    <div
                      className="homepage_template__news__item"
                      key={'news-home-' + i.toString()}
                    >
                      <NewsCard news={news} />
                    </div>
                  )
                })}
              </div>
              <Link href="/news">
                <a>
                  <Button
                    label={t('Vedi tutte le news')}
                    icon={<ArrowRight />}
                    variant="ghost"
                    size="large"
                    className="homepage_template__news__cta"
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default HomepageTemplate
